<template>
    <div class="start_form">
        <!-- Email -->
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item
                ref="typeInput"
                class="mb-2"
                :label="$t('auth.label_email')"
                prop="typeInput">
                <div class="flex items-center">
                    <a-input
                        v-model="form.typeInput"
                        autocomplete="off"
                        :placeholder="authTypePlaceholder"
                        class="w-full"
                        size="large"
                        @pressEnter="captchaCheck()" />
                </div>
            </a-form-model-item>
        </a-form-model>
        <!-- Code -->
        <a-form-model
            ref="codeForm"
            :model="codeForm"
            layout="vertical"
            autocomplete="off"
            :rules="codeRules">
            <a-form-model-item
                ref="confirm_code"
                class="mb-2"
                :label="isCodeSent ? $t('auth.label_confirm_code') : ''"
                prop="confirm_code">
                <template v-if="isCodeSent">
                    <a-input
                        v-model="codeForm.confirm_code"
                        autocomplete="off"
                        :placeholder="$t('auth.placeholder_code')"
                        class="w-full"
                        size="large"
                        @input="inputCode"
                        @pressEnter="codeSubmit()">
                        <template v-if="isCodeChecked">
                            <a-tooltip slot="suffix">
                                <img
                                    class="check_icon" 
                                    src="@/assets/svg/check.svg" 
                                    alt="">
                            </a-tooltip>
                        </template>
                    </a-input>
                </template>
                <template v-else>
                    <a-button
                        type="primary"
                        ghost
                        block
                        @click="captchaCheck()"
                        size="large">
                        {{ breakpoint1 ? $t('auth.button_send_code') : $t('auth.button_send_confirmation_code') }}
                    </a-button>
                </template>
                <template v-if="isCodeSent">
                    <p>{{ $t('auth.message_code_sent', { email: emailToSentCode }) }}</p>
                </template>
            </a-form-model-item>
        </a-form-model>

        <a-form-model
            ref="passwordForm"
            :model="passwordForm"
            layout="vertical"
            autocomplete="off"
            :rules="passwordRules">
            <div class="grid_col_2">
                <a-form-model-item
                    class="mb-2"
                    ref="password"
                    :label="$t('auth.label_password')"
                    prop="password">
                    <a-input-password
                        v-model="passwordForm.password"
                        autocomplete="off"
                        :placeholder="$t('auth.placeholder_password')"
                        size="large"/>
                </a-form-model-item>
                <a-form-model-item
                    class="mb-2"    
                    ref="password_confirm"
                    :label="$t('auth.label_confirm_password')"
                    prop="password_confirm">
                    <a-input-password
                        v-model="passwordForm.password_confirm"
                        autocomplete="off"
                        :placeholder="$t('auth.placeholder_confirm_password')"
                        size="large"/>
                </a-form-model-item>
            </div>
        </a-form-model>

        <div class="buttons_grid">
            <a-button 
                size="large"
                :disabled="saveForm.isInvite"
                @click="$store.commit('user/SET_REG_STEP', 1)">
                {{ $t('auth.button_back') }}
            </a-button>
            <a-button
                class="button_next"
                type="primary"
                ghost
                :loading="passwordLoading"
                size="large"
                @click="passwordSubmit()">
                {{ $t('auth.button_next') }}
            </a-button>
        </div>
    </div>
</template>

<script>
import debounce from '@/utils/lodash/debounce'

const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export default {
    props: {
        regForm: {
            type: Object,
            default: () => {}
        },
        setRegForm: {
            type: Function,
            default: () => {}
        },
        saveForm: {
            type: Object,
            default: () => {}
        },
        resetRegForm: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        breakpoint1() {
            return this.windowWidth < 828
        },
        authConfig() {
            return this.$store.state.user.authConfig
        },
        authTypePlaceholder() {
            if(this.authConfig?.registerType) {
                if(this.authConfig?.registerType === 'all') {
                    return this.$t('phone_or_mail')
                } else {
                    if(this.authConfig?.registerType === 'phone') {
                        return this.$t('placeholder_phone')
                    }
                }
            }
            return this.$t('auth.enter_email')
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('field_required')));
            } else if (value !== this.passwordForm.password) {
                callback(new Error(this.$t('auth.passwords_do_not_match')));
            } else {
                callback();
            }
        };

        return {
            loading: false,
            codeLoading: false,
            passwordLoading: false,
            form: {
                typeInput: ''
            },
            codeForm: {
                confirm_code: ''
            },

            isCodeSent: false,
            emailToSentCode: '',
            isCodeChecked: false,
            rules: {
                typeInput: [
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ]
            },
            codeRules: {
                confirm_code: [
                    { required: true, type: 'string', 
                        message: this.$t('auth.email_reg_next'), 
                        trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ]
            },

            passwordForm: {
                password: '',
                password_confirm: ''
            },
            passwordRules: {
                password: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ],
                password_confirm: [
                    { validator: validatePass, trigger: 'change' },
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ]
            }


        }
    },
    created() {
        this.form.typeInput = JSON.parse(JSON.stringify(this.saveForm.typeInput))

        if(this.authConfig?.register) {
            if(this.authConfig.registerType === 'email') {
                this.rules.typeInput = [
                    ...this.rules.typeInput,
                    { required: true, type: 'email', message: this.$t('auth.email_reg_error'), trigger: 'change' }
                ]
            }
            if(this.authConfig.registerType === 'phone') {
                const phoneValidator = (rule, value, callback) => {
                    const regex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
                    if (value === '') {
                        callback(new Error(this.$t('field_required')))
                    } else if (!regex.test(value)) {
                        callback(new Error(this.$t('auth.num_error')))
                    } else {
                        callback()
                    }
                }
                this.rules.typeInput = [
                    ...this.rules.typeInput,
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { validator: phoneValidator, trigger: 'change' }
                ]
            }
            if(this.authConfig.registerType === 'all') {
                const validateMailOrPhone = (rule, value, callback) => {
                    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
                    if (value === '') {
                        callback(new Error(this.$t('field_required')))
                    } else if (!regex.test(value)) {
                        callback(new Error(this.$t('invalid_input')))
                    } else {
                        callback()
                    }
                }
                this.rules.typeInput = [
                    ...this.rules.typeInput,
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { validator: validateMailOrPhone, trigger: 'change' }
                ]
            }
        }
    },
    mounted () {
        if(!this.authConfig?.register)
            this.$router.push({ name: 'login' })
    },
    methods: {
        captchaCheck() {
            this.saveForm.typeInput = JSON.parse(JSON.stringify(this.form.typeInput))

            if(this.authConfig.reCAPTCHASiteKey) {
                grecaptcha.ready(() => {
                    this.loading = true
                    grecaptcha.execute(this.authConfig.reCAPTCHASiteKey, {action: 'confirm'}).then((token) => {
                        this.formSubmit(token)
                    })
                })
            } else {
                this.formSubmit()
            }
        },
        formSubmit(captcha = null) {
            this.$refs.authForm.validate(async valid => {
                if (valid && this.authConfig.register) {
                    try {
                        this.loading = true
                        const target = regexEmail.test(this.form.typeInput) ? 'email' : 'phone'
                        let login = JSON.parse(JSON.stringify(this.form.typeInput)).trim()
                        let formData = {
                            target,
                            login
                        }
                        if(captcha)
                            formData.captcha = captcha
                        else
                            formData.captcha = 'captcha'

                        if(target === 'phone' && !login.includes('+')) {
                            const fLetter = login.charAt(0)
                            if(fLetter !== '8') {
                                formData.login = `+${login}`
                            } else {
                                login = login.substring(1)
                                formData.login = `+7${login}`
                            }
                        }

                        if (this.saveForm?.isInvite && this.$route.query?.token) {
                            formData.invite_token = this.$route.query.token
                        } else {
                            formData.join_organization = this.saveForm.contractor_id
                        }
                        const { data } = await this.$http.post('/users/send_confirm_code/', formData)
                        if(data === 'ok') {
                            
                            this.isCodeSent = true
                            this.emailToSentCode = formData.login

                            this.setRegForm({
                                ...this.regForm,
                                ...formData
                            })
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                    return false
                }
            })
        },
        codeSubmit() {
            this.$refs.codeForm.validate(async valid => {
                if (valid) {
                    try {
                        this.codeLoading = true
                        let formData = {
                            ...this.regForm,
                            ...this.codeForm
                        }
                        const { data } = await this.$http.post('/users/confirm/', formData)
                        if(data?.confirm_token) {
                            delete formData.confirm_code
                            this.setRegForm({
                                ...formData,
                                confirm_token: data.confirm_token
                            })
                            this.isCodeChecked = true
                            // this.$store.commit('user/SET_REG_STEP', 4)
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        // this.codeForm.confirm_code = ''
                        this.isCodeChecked = false

                        console.log(error)
                    } finally {
                        this.codeLoading = false
                    }
                } else {
                    return false
                }
            })
        },
        inputCode: debounce(async function() {
            this.codeSubmit()
        }, 500),
        passwordSubmit() {
            if (!this.form.typeInput) {
                this.$refs.authForm.validate()
                return 0
            }
            if (!this.isCodeChecked) {
                this.$refs.codeForm.validate()
                return 0
            }
            this.$refs.passwordForm.validate(async valid => {
                if (valid) {
                    try {
                        this.passwordLoading = true
                        const formData = {
                            // ...this.regForm,
                            ...this.passwordForm,
                        }

                        if (this.saveForm.isInvite) {
                            if (this.$route.query.token) {
                                formData.invite_token = this.$route.query.token
                            } else {
                            }
                        } else {
                            formData.join_organization = this.saveForm.contractor_id
                        }

                        this.saveForm.password = this.passwordForm.password
                        this.saveForm.password_confirm = this.passwordForm.password_confirm

                        const response = await this.$http.post('/users/validate_password/', formData)
                        if(response.status === 200) {
                            // localStorage.setItem('user', JSON.stringify(data))
                            // this.$store.commit('user/SET_USER', data)
                            // this.resetRegForm()
                            this.$store.commit('user/SET_REG_STEP', 3)
                        }
                        // this.$store.commit('user/SET_REG_STEP', 3)

                    } catch(error) {
                        if (error.non_field_errors.length) {
                            error.non_field_errors.forEach(errorMessage => {
                                this.$message.error(errorMessage)
                            });
                        }
                        if (error.message) {
                            this.$message.error(error.message, 4)
                        }
                        console.log(error)
                    } finally {
                        this.passwordLoading = false
                    }
                } else {
                    return false
                }
            })
        }


    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 828px;

.check_icon {
    width: 20px;
    height: 20px;
}
.grid_col_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }

}

.reg_form{
    &::v-deep{
        .ant-row{
            &.ant-form-item{
                padding-bottom: 0px;
            }
        }
    }
    @media (max-height: 750px) {
        padding-top: 110px;
    }
}

.buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.button_next {
    grid-column: span 2 / span 2;
    @media (max-width: $breakpoint-1) {
        grid-column: span 1 / span 1;
    }
}
.start_form::v-deep {
    .ant-input,
    .ant-btn {
        height: 50px;
    }
}
</style>